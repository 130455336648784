const faqData = {
    title: "Frequently asked question",
    rows: [
        {
            title: "How quick can I get an appointment to be seen?",
            content: `We try our best to schedule appointments as soon as we possibly can. We try to accommodate patients based on urgency, need and time availability. An appointment should be available within two week.`,
        },
        {
            title: "I have tooth pain but just want to have that tooth looked at. Can this office do that?",
            content:
                "Yes, our clinic can offer a limited exam to address a single tooth/single area and help our patients get out of pain. ",
        },
        {
            title: " I am very scared to have dental treatments done and just want pain medication and/or antibiotics. Can this office do that?",
            content: `Undergoing dental treatments with pain is never a pleasurable experience. Our clinic understands that and can accomodate by putting patients on antibiotic/low dose anti inflammatory medication to manage discomfort. But if dental treatments are the only way to address the pain, we cannot give out prescriptions more than 2-3 times due to regulations that are imposed on us.`,
        },
        {
            title: "How long does one appointment last?",
            content: `A comprehensive exam/cleaning should last from 60-90 min. A limited exam should last from 30-60 min. A treatment appointment should last 60 min to 2 hours. Our hope is to never have anyone in the office for more than 2 hours.`,
        },
        {
            title: "Does the office offer any warranty on the treatments rendered?",
            content: `Our clinic does offer good will warranty. This means if dental treatments fail in a specific amount of time that is not due to foreseeable causes, we will redo the treatments or do a supplemental procedure free of charge to our patient.
            Example: A patient have white fillings done and have no cavities underneath the fillings but the fillings fall out, we can redo the fillings for free if they are less than 12 month old.

            A patient has crowns done and have no cavities underneath the crowns but the crowns fracture, we can redo the crowns for free if they are less than 24 month old

            a patient has a root canal done but the tooth fracture or the root canal fails less than 12 month, we can offer free extraction for that particular tooth.

            A patient has an implant fixture placed but the body rejects the implant less than 12 months, we can offer a free partial denture.`,
        },
        {
            title: "I do not know what insurance I have and not sure how all this paperwork works. How should I proceed?",
            content: `You can fill out the form on our website with your phone number and/or email and time availability, we can give you a call to gather your information and provide you with answers you need. If you still desire to proceed, an appointment can be made for you.
`,
        },
        {
            title: "Does your office give a price for certain procedures over the phone?",
            content: `we can give a price range for a specific procedure over the phone however we are not sure if that is what our patient needs until an exam/Xray is done. Hence, this form of information gathering is highly inaccurate and usually leads to a lot of frustration for both our staff and our patients. The best way to go is to schedule an exam and you will be informed of the recommended treatments. A lot of times, patients do not need any treatments.`,
        },
        {
            title: "Does your office give verbal confirmation over the phone about what dental procedures the dentist can do?",
            content: `Yes, definitely. We can confirm the scope of practice our dentist has and if he can do the procedures our patients are looking for. However, confirming a dental procedure our dentist can do does not mean the patient need that dental procedure until an exam/Xray is done.`,
        },
        {
            title: "Does your office see kids?",
            content: `Yes, definitely. We can see kids from a few months old (visual exams for diet and hygiene consultation) to toddlers to middle schoolers and high schoolers. Dental treatments, if needed, can be done on kids starting 4-5 years old under laughing nitrous gas. However, if uncooperative behavior is noticed or a lot of dental work is needed (8-10 cavities), the young patients can be referred to the hospital setting for the work to be done under general anesthesia. We do not use forceful methods on young patients (no papoose) and focus only on verbal reassurance and tell-show-do. We allow parents of young patients to be present in the treatment rooms to help us as well.`,
        },
        {
            title: "What is so special about your office that I have to pick you?",
            content: `This question is highly variable and personable. The best way to answer this is for you to come in and experience the office. Every office has strengths and weaknesses. We certainly hope our strengths overpower our weaknesses and our patients find calmness and comfort in our clinic's setting.`,
        },
       
    ],
    styles: {
        bgColor: '#fff', //theme color S2
        titleTextColor: '#103A49', // from $text-color
        titleTextSize: '55px',
        rowTitleColor: '#333333',
        rowTitleTextSize: 'medium',
        rowContentColor: 'grey',
        rowContentTextSize: '16px',
        rowContentPaddingTop: '10px',
        rowContentPaddingBottom: '10px',
        rowContentPaddingLeft: '50px',
        rowContentPaddingRight: '150px',
        // arrowColor: "red",
        transitionDuration: "1s",
        timingFunc: "ease"
      },
    config : {
        animate: true,
        arrowIcon: "V",
        openOnload: -1,
        expandIcon: "+",
        collapseIcon: "-",
        tabFocus:true
    },
};



export default faqData;