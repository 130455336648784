import React, {useState} from 'react'
import { useForm, Controller, useWatch } from 'react-hook-form';
import { LocalizationProvider } from  '@mui/x-date-pickers/LocalizationProvider';
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { ShowAlertDiv } from '../ContactForm/ContactForm';

const AppoinmentForm = () => {
    const {
        register, 
        handleSubmit,
        control, 
        formState: { errors },
        reset
      } = useForm({
        defaultValues: {
          name: "",
          email:"",
          phone: "",
          subject:"",
          preferred_date_n_time_1: null,
          preferred_date_n_time_2: null,
          preferred_date_n_time_3: null,
        },
      });

    const datetime1Value = useWatch({
        control,
        name: 'preferred_date_n_time_1',
    });
    
    const datetime2Value = useWatch({
        control,
        name: 'preferred_date_n_time_2',
    });

    const onSubmit = async (data) => {
        try {
           
            data = {...data, 
                preferred_date_n_time_1: new Date(data.preferred_date_n_time_1.$d).toISOString(),
                preferred_date_n_time_2: data.preferred_date_n_time_2 ?  new Date(data.preferred_date_n_time_2.$d).toISOString() : null,
                preferred_date_n_time_3: data.preferred_date_n_time_3 ?  new Date(data.preferred_date_n_time_3.$d).toISOString(): null,
            }
            // console.log(data);
            // console.log(process.env.REACT_APP_API_UR)
            // const backendUrl = `${process.env.REACT_APP_API_URL}/submit-appointment` || "http://localhost:8000/submit-appointment";
            let backendUrl = "http://localhost:8000/submit-appointment";
            if (process.env.NODE_ENV==="production"){
                backendUrl = `${process.env.REACT_APP_NODEBACKEND_URL}/submit-appointment`;
            }
            // console.log('backend URL ', backendUrl)
            // console.log(backendUrl)
            const response = await fetch(backendUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            });
            const responseData = await response.json();
            // console.log("response from back end: ", responseData)
           
            if (response.ok) {
                reset(); // Reset form after successful submission
                // alert(responseData.message);
                setAlertSeverity('success');
                setAlertMessage(responseData.message);
              } else {
                // alert('Failed to submit form.');
                setAlertSeverity('error');
                setAlertMessage('Failed to submit form.');
              }
            setShowAlert(true);
        } catch (error) {
            // console.error('Error submitting form:', error);
            // alert('Failed to submit form.');
            setAlertSeverity('error');
            setAlertMessage('Failed to submit form.');
            setShowAlert(true);
        }
    }

    const [showAlert, setShowAlert] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [alertMessage, setAlertMessage] = useState('');

    const handleCloseAlert = () => {
        setShowAlert(false);
      };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} className={showAlert ? 'contact-validation-active blur' : 'contact-validation-active'}  >
            {/* <form  className="contact-validation-active" onSubmit={handleSubmit(onSubmit)}> */}
                <div className="row">
                    <div className="form-field col-lg-6">
                        <input                     
                            type="text"
                            placeholder="Your Name"  
                            className="form-control-name"
                            {...register("name", {
                                required: true
                            })}
                        />
                        {errors.name && <p className="errorMsg">Name is required</p>}
                    </div>
                    <div className="form-field col-lg-6">
                        <input                     
                            type="text"
                            placeholder="Your Email"  
                            className="form-control-name"
                            {...register("email", {
                                required: true,
                                pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
                            })}
                        />
                        {errors.email && errors.email.type === "required" && (
                                <p className="errorMsg">Email is required.</p>
                            )}
                        {errors.email && errors.email.type === "pattern" && (
                            <p className="errorMsg">Email is not valid.</p>
                        )}
                    </div>
                    <div className="form-field col-lg-6">
                        <input
                            type="phone"
                            className="form-controls form-control-number"
                            placeholder="Your phone" 
                            {...register("phone", {
                                required: true,
                                pattern: /^\d{10}$/,
                            })}
                        />
                        {errors.phone && errors.phone.type === "required" && (
                                <p className="errorMsg">Phone number is required.</p>
                        )}
                        {errors.phone && errors.phone.type === "pattern" && (
                            <p className="errorMsg">Phone number is not valid.</p>
                        )} 
                    </div>
                    <div className="form-field col-lg-6">
                        <select
                            type="text"
                            name="subject"
                            className="form-control-name"
                            {...register("subject", {
                                required: true
                            })}
                        >
                            <option disabled="disabled" value="">Have you been to our office?</option>
                            <option>New patient</option>
                            <option>Existing patient</option>
                        </select>
                        {errors.subject && errors.subject.type === "required" && <p className="errorMsg">Please select one option </p>}
                    </div>

                    <div className="form-field col-lg-4">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                                name="preferred_date_n_time_1"
                                control={control}
                                rules={{ required: 'Please enter at least 1 preferred date and time' }}
                                render={({ field }) => (
                                    <DateTimePicker 
                                        {...field}
                                        label="Preferred Date & Time 1"                        
                                        slotProps={{
                                            textField: {
                                            margin: "normal",
                                            fullWidth: true,
                                            error: !!errors.preferred_date_n_time_1,
                                            helperText: errors.preferred_date_n_time_1 ? errors.preferred_date_n_time_1.message : '',
                                            }
                                        }}
                                        disablePast
                                    />
                                )}
                                />
                        </LocalizationProvider>
                    </div>

                    <div className="form-field col-lg-4">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                                name="preferred_date_n_time_2"
                                control={control}
                            
                                render={({ field }) => (
                                    <DateTimePicker
                                        {...field}
                                        label="Preferred Date & Time 2"
                                        disabled={!datetime1Value}
                                        slotProps={{
                                            textField: {
                                            margin: "normal",
                                            fullWidth: true,
                                            }
                                        }}
                                        disablePast
                                    />
                                )}
                                />
                        </LocalizationProvider>
                    </div>
                    <div className="form-field col-lg-4">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                                name="preferred_date_n_time_3"
                                control={control}
                                render={({ field }) => (
                                    <DateTimePicker
                                        {...field}
                                        label="Preferred Date & Time 3"
                                        disabled={!datetime1Value || !datetime2Value}
                                        slotProps={{
                                            textField: {
                                            margin: "normal",
                                            fullWidth: true,
                                            }
                                        }}
                                        disablePast
                                    />
                                )}
                                />
                        </LocalizationProvider>
                    </div>
                    <div className="form-field col-lg-12">
                        <textarea
                            className="form-control-additional-message"
                            rows="1" 
                            placeholder='Reason for Visit/ Message'
                            {...register("message")}
                            // {...register("message", {
                            //     required: true
                            // })}
                        />
                        {errors.message && <p className="errorMsg">Message is required.</p>}     
                    </div>
                    <div className="col-xl-12 col-lg-12 col-12">
                        <div className="submit-area">
                            <button type="submit" className="theme-btn">Book Appoinment</button>
                        </div>
                    </div>
                </div>
            </form>

            {showAlert && (
            <ShowAlertDiv
                severity={alertSeverity}
                alertMessage={alertMessage}
                handleCloseAlert={handleCloseAlert}
            />
        )}
        </div>
        
    )
}

export default AppoinmentForm;