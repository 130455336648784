import React, {Fragment} from 'react';
// import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import insuranceImgs from '../../api/insurance';
const OneInsurance = ({imgSrc}) => {
    return (
        <div className="col">
            <div className="insurance-item">
                <img src={imgSrc} alt=""></img>
            </div>
        </div>
    )
}
export const InsuranceSection = () => {
    return (
        <section className="insurance-section section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="wpo-section-title">
                            <span>Transparent Pricing</span>
                            <h2>
                                Flexible Insurance Plans
                            </h2>
                            <p>
                            We accept the majority of dental PPOs plans, Medicaid Children, and Medicare. The best way to confirm insurance participation is to call our office to check.
                            </p>
                        </div>
                    </div>
                </div>

                {/* SHOW WHEN YOU HAVE A LIST OF INSURANCE */}
                {/* <div className="insurance-wrapper">
                    <div className="row justify-content-center align-items-center" data-cues="slideInUp">                   
                        {insuranceImgs.map((img, idx) => (
                            <OneInsurance key={idx} imgSrc={img}/>
                        ))}               
                    </div>
                </div> */}
                
            </div>
        </section>
    )
}
const InsurancePage = () => {
    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-2'}/>
            {/* <PageTitle pageTitle={'List of Insurance Accepted'} pagesub={''} className={'wpo-page-title'}/>  */}
            <InsuranceSection />
            <Footer />
            <Scrollbar/>
        </Fragment>
    )
}

export default InsurancePage