import React, { Fragment } from 'react';
// import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { TestimoniCard } from '../../components/Testimonial/TestimonialStyle3';
import testimonials from '../../api/reviews';
import sliderSettings from '../../api/sliderSetting';
import Slider from "react-slick";
import 'swiper/css';
import 'swiper/css/navigation';
import { AiFillStar } from "react-icons/ai";

const AllTestimonials = () => {
    const reviewLink="https://www.google.com/search?q=up+dental+of+manvel&rlz=1C5GCCM_en&oq=up&gs_lcrp=EgZjaHJvbWUqBggBECMYJzIGCAAQRRg5MgYIARAjGCcyBggCEEUYOzIQCAMQLhjHARixAxjRAxiABDIYCAQQLhhDGIMBGMcBGLEDGNEDGIAEGIoFMgYIBRBFGD0yBggGEEUYPTIGCAcQRRg80gEIMjYwOGowajeoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x86408d1ac3386a51:0xaefa186f57b192ab,3,,,,"
    const sliderSettings_ = { ...sliderSettings, 
                                slidesToShow: 4,
                                slidesToScroll: 4,
                                rows: 2,
                                swipeToSlide: true
                            };
    return (
        <section className='all-testimonials-section section-padding' >
            <div id="rating-header" className='container'>
                <div  className="ratings-wrap-box ">
                        <div className="content">
                            <h5>4.98 <span>Ratings</span></h5>
                            <ul className="list testimonial-rating">
                                <li className='good-review' ><AiFillStar/></li>
                                <li className='good-review' ><AiFillStar/></li>
                                <li className='good-review' ><AiFillStar/></li>
                                <li className='good-review' ><AiFillStar/></li>
                                <li className='good-review' ><AiFillStar/></li>
                                <li>
                                   <span> Rated 4.9 stars based on 1200+ reviews</span>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <a href={reviewLink} className="theme-btn-s2">Post A Review</a>
                        </div>
                        
                </div>
            </div>
          

            <div className='container all-reviews-wrapper'>
                <Slider {...sliderSettings_} className="testimoni-slider">
                        {
                            testimonials.map((review, idx) =>
                                <TestimoniCard key={idx} testimonail={review}/> 
                            )
                        }
                </Slider>
            </div>
           
        </section> 
    )
}
const TestimonialPage = () => {
    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-2'}/>
            {/* <PageTitle pageTitle={'Testimonial'} pagesub={'About'} className={'wpo-page-title'}/> */}
            <AllTestimonials /> 
            {/* <Footer footerClass={'wpo-site-footer-s2'}/> */}
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};



export default TestimonialPage;