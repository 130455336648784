import officeInfo from "../../api/addressInfo"
import { handlePhoneClick, handleAddressClick} from "../footer/Footer";

const PhoneBanner = () => {
    return(
        <div  id="phone-banner-wrapper" >  
            <div className="container" id="phone-banner-content" >
                <div>
                    Questions? Call us at <span className='phone-container' onClick={handlePhoneClick}>{officeInfo.phone}</span>
                </div>
                {/* <div>
                    <i onClick={handlePhoneClick} className="fi flaticon-phone-call phone-container"> {officeInfo.phone}</i>
                </div> */}
          
                {/* location icon here */}
                <div id="banner-address" >
                    <i onClick={handleAddressClick} className="fi flaticon-placeholder address-container"> {officeInfo.address}</i>
                </div>

            </div>      
                
        </div>
    )
}

export default PhoneBanner;