import React from "react";
import Header from "../Header/Header";
import PhoneBanner from "../Header/PhoneBanner";
export default function Navbar(props) {
  const [scroll, setScroll] = React.useState(0);

  const handleScroll = () => setScroll(document.documentElement.scrollTop);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const className = scroll > 80 ? "fixed-navbar active" : "fixed-navbar";
  const includePhone=scroll > 80? "include-phone":"no-phone";
  return (
    <div className={className}>
        {className==="fixed-navbar" && <PhoneBanner />}
        {/* <Header hclass={props.hclass} Logo={props.Logo} topbarNone={props.topbarNone}/> */}
        <Header hclass={props.hclass} Logo={props.Logo} topbarNone={props.topbarNone} includePhone={includePhone}/>
    </div>
  ); 
}