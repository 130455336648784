import imgOne from "../images/testimonial/1.png";
import imgTwo from '../images/testimonial/2.png';
import imgThree from '../images/testimonial/3.png';

const testimonials = [
    {
        'img': imgOne,
        'name': 'Robert Fox',
        'description' : 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts',
       
        'rating': 5
    },
    {
        'img': imgTwo,
        'name': 'Albert Flores',
        'description' : 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts',
        'rating': 4
    },
    {
        'img': imgThree,
        'name': 'Bessie Cooper',
        'description' : 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts',
        'rating': 5
    },
    {
        'img': imgOne,
        'name': 'Bessie Cooper',
        'description' : 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts',
        'rating': 4
    },
    {
        'img': imgOne,
        'name': 'LATTE COCO',
        'description' : 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts',
        'rating': 5
    },
    {
        'img': imgThree,
        'name': 'Bessie Cooper2',
        'description' : 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts',
        'rating': 5
    },
    {
        'img': imgTwo,
        'name': 'Minnie',
        'description' : 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts',
        'rating': 3
    },
    {
        'img': imgThree,
        'name': 'COCO LATTE',
        'description' : 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts',
        'rating': 3
    },
    {
        'img': imgTwo,
        'name': 'COCO MELON',
        'description' : 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts',
        'rating': 4
    },
    {
        'img': imgTwo,
        'name': 'Bessie Cooper2',
        'description' : 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts',
        'rating': 5
    },
    {
        'name': 'Vesta',
        'description' : 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts',
        'rating': 4
    },
    {
        'img': imgTwo,
        'name': 'ANONYMOUS',
        'description' : 'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts',
        'rating': 4
    }
]




export default testimonials