import React from 'react';
import { Link } from 'react-router-dom'
import careImg from '../../images/about/doctor-care.jpg'
const AboutUs = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="wpo-about-section section-padding">
            <div className="container">
                <div className="row align-items-center">
                    {/* image on the left */}
                    <div className="col-lg-6 col-md-12 col-12">
                        {/* <div className="about-image"></div> */}
                        <div className="wpo-about-img">
                                <img src={careImg } alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-text" >
                            <span> About Us </span>
                            <h2>Compassionate Patient Care</h2>
                            <p>At Up Dental of Manvel, we believe that a healthy and beautiful smile is a gateway to confidence, well-being, and a vibrant life. Established with a passion for precision dentistry and patient-centered care, our clinic is dedicated to providing comprehensive dental solutions.</p>
                            <ul >
                                <li>
                                    
                                    Insurance Accepted
                                </li>
                                <li>
                                    
                                    Routine and emergency services
                                </li>
                                <li>
                                    
                                    Short time wait and Luxury amenities
                                </li>
                            </ul>
                            <Link onClick={ClickHandler} className="theme-btn" to="/doctor-profile/Dr-Tri-Tran" >
                                                About Our Dentist
                            </Link>                
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutUs;