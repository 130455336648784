import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'

// import AboutUs from '../../components/about/AboutUs';
// import AboutPriority from '../../components/about/AboutPriority';
// import TestimonialStyle2 from '../../components/Testimonial/TestimonialStyle2';
import TestimonialStyle3 from '../../components/Testimonial/TestimonialStyle3';
// import Features from '../../components/Features';
import FaqSection from '../../components/Faq/FaqSection';
import SmileGalleryHero from '../../components/hero/SmileGalleryHero';
import AboutDoctorHero from '../../components/hero/AboutDoctorHero';
// import AllTeamMembers from '../../components/TeamSection/AllTeamMembers';
import { AllTeamMembersSlider } from '../../components/TeamSection/AllTeamMembers';
const AboutPageStyle2 = () => {
    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-2'}/>
            <PageTitle pageTitle={'About Us'} pagesub={'About'} className={'wpo-page-title'}/>
            {/* <AllTeamMembers /> */}
            <AllTeamMembersSlider />
            {/* <Features /> */}
            <SmileGalleryHero />
            <AboutDoctorHero />
            {/* <AboutUs />
            <AboutPriority />
            <Testimonial/> */}
            {/* <TestimonialStyle2 /> */}
            <TestimonialStyle3 />
            <FaqSection />
            {/* <Footer footerClass={'wpo-site-footer-s2'} /> */}
            <Footer  />
            <Scrollbar />
        </Fragment>
    )
};

export default AboutPageStyle2;
