import React from 'react'
import { Link } from 'react-router-dom'
import MobileMenu from '../MobileMenu/MobileMenu'
import Logo from '../../images/logo/UpDentalOfmanvel-logoHQ.png'
import { handlePhoneClick } from '../footer/Footer'
const Header = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (
        <header id="header" className={props.topbarNone}>
            <div className="wpo-site-header wpo-header-style-2">
                <nav className="navigation navbar navbar-expand-lg navbar-light">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                                <div className="mobail-menu">
                                    <MobileMenu />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-6">
                                <div className="navbar-header">
                                    <Link onClick={ClickHandler} className="navbar-brand" to="/"><img src={Logo}alt="" /></Link>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-1 col-1">
                                <div id="navbar" className="collapse navbar-collapse navigation-holder">
                                    <button className="menu-close"><i className="ti-close"></i></button>
                                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                                        <li className="menu-item-has-children">
                                            <Link onClick={ClickHandler} to="/">Home</Link>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <Link onClick={ClickHandler} to="/">About</Link>
                                            <ul className="sub-menu">
                                                <li><Link onClick={ClickHandler} to="/dr-tran">Meet Dr.Tran</Link></li>
                                                <li><Link onClick={ClickHandler} to="/all-team-members">Meet Our Team</Link></li>
                                                {/* <li><Link onClick={ClickHandler} to="/testimonial">Testimonials</Link></li> */}
                                                <li>
                                                    <Link onClick={ClickHandler} to="/faq">FAQs</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <Link onClick={ClickHandler} to="/case-gallery">Case Gallery</Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="/contact">Contact</Link>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div className="col-lg-3 col-md-2 col-2">
                            

                            {props.includePhone!=='include-phone' &&
                                <div className="header-right">
                                    <div className="close-form">
                                        <Link onClick={ClickHandler} className="theme-btn" to="/appoinment">Book Now</Link>
                                    </div>
                                </div>
                            }
                            {props.includePhone==='include-phone' &&
                                <div className="header-right">
                                
                                    <div className="flaticon-container" onClick={handlePhoneClick}>
                                            <i className="fi flaticon-phone-call"></i> 
                                    </div>
                                    <div className="flaticon-container" >
                                        <Link  onClick={ClickHandler} to="/location">
                                             <i className="fi flaticon-placeholder"> </i>
                                        </Link>
                                    </div>
                                    <div className="close-form">
                                        <Link onClick={ClickHandler} className="theme-btn" to="/appoinment">Book Now</Link>
                                    </div>
                                </div>
                            }

                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header;