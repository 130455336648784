import React from 'react';
import { Link } from 'react-router-dom'
import patientImg from '../../images/about/patient-smile.jpeg'
const AboutPriority = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="wpo-about-section section-padding">
            <div className="container">
                <div className="row align-items-center">
                    {/* text on the left */}
                    <div className="col-lg-6 col-md-12 col-12" >
                    {/* <div className="col-lg-6 col-md-12 col-12" style={{display: 'flex', alignItems: 'space-between', flexDirection: 'column'}}> */}
                        <div className="wpo-about-text" >
                            <span> Our Priority </span>
                            <h2>Our clients are our priority</h2>
                            <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>

                                       
                        </div>

                        <div style={{marginTop:'50px'}}>
                            <Link onClick={ClickHandler} className="theme-btn" to="/appoinment">Book An Appointment</Link>    
                        </div>
                    </div>
                    {/* image on the right */}
                    <div className="col-lg-6 col-md-12 col-12">
                        {/* <div className="about-image"></div> */}
                        <div className="wpo-about-img">
                                <img src={patientImg } alt="" />
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default AboutPriority;