import React from "react";
import Faq from "react-faq-component";
import faqData from "../../api/faq";
import officeInfo from '../../api/addressInfo';
import { Link } from 'react-router-dom'
const ExtraDiv = () => {
    return (
        <div id="faq-extra" >
            <h2>Still have questions?</h2>
            <p >Give us a call at 
                <a className="phone-container" href={`tel:${officeInfo.phone}`}> {officeInfo.phone}</a>
                , and we'll be more than happy to help you.
            </p>
        </div>
    )
}

const ReadMoreDiv = () => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (
        <div id="faq-read-more">
            <Link onClick={ClickHandler}  to="/faq">Read More <span>&#8594;</span></Link>
        </div>
    )
}
const FaqSection = ({questionNum}) => {
  
    const data = {
        title: faqData.title,
        rows:faqData.rows.slice(0, questionNum)
    }
    return (
        <section className="wpo-faq-section section-padding">
            <div className="container">
                <Faq
                    data={data}
                    styles={faqData.styles}
                    config={faqData.config}
                />
                {questionNum === undefined ? <ExtraDiv /> : <ReadMoreDiv />}
            </div>
        </section>
    );
}

export default FaqSection; 
