import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import caseGalleryData  from '../../api/caseImgs';
// import { Navigation, A11y } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react';
import Slider from "react-slick";
import sliderSettings from '../../api/sliderSetting';
const OneCaseWrapper = ({oneCase}) => {
       
    return (
        <div className='case-wrapper' > 
            {/* Header: case number and description */}
            <div className='case-header' >
                <div>
                    <h3 >Case {oneCase.caseNumber}: </h3>
                </div>
                <div> 
                    <p>{oneCase.caseDescription} </p>
                </div>
            </div>
            {/* for case pictures */}
            <OneCaseImagesSwiper  images={oneCase.images}/>

        </div>  
    )
}

export const OneCaseImagesSwiper = ({ images}) => {
    let curSliderSettings = sliderSettings;
    const imagesNum = images.length;
    if (imagesNum <=3) {curSliderSettings={...curSliderSettings, slidesToShow: imagesNum}}
    if(imagesNum >=4){
        curSliderSettings={...curSliderSettings, slidesToShow: 4 }
    }
    if(imagesNum >=8){
        curSliderSettings={...curSliderSettings, slidesToShow: 5,  slidesToScroll: 3 }
    }
    return (
        <div className='one-case-all-images-wrapper'>
            <Slider {...curSliderSettings} className="testimoni-slider">
                {
                    images.map((image, idx) =>(
                        <div key={idx}>
                            <img   src={image} alt=""></img>
                        </div>
                    ))
                }
            </Slider>
        </div>
    )
}

const CaseSectionSwiper = () => {  
    return (
        <section className="wpo-department-section section-padding">
            <div className="container">

                <div id='cases-wrapper'>
                    {caseGalleryData.map((aCase, caseIdx) => (
                        <OneCaseWrapper key={caseIdx} oneCase={aCase}/>
                    ))
                    } 
                </div>
            </div>
            
        </section>
    )
}
const CaseGallery =() => {
    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-2'}/>
            <PageTitle pageTitle={'Case Gallery'} pagesub={''} className={'wpo-page-title'}/> 
            <CaseSectionSwiper />
            {/* <CaseSection/>  */}
            {/* <Footer footerClass={'wpo-site-footer-s2'}/> */}
            <Footer />
            <Scrollbar/>
        </Fragment>
    )
};


export default CaseGallery;
