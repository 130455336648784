import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar.js'
import PageTitle from '../../components/pagetitle/PageTitle.js'
import Scrollbar from '../../components/scrollbar/scrollbar.js'
import Footer from '../../components/footer/Footer.js'
import profileImage from '../../images/team/doctor-profile.png'
import { OneCaseImagesSwiper } from '../CaseGallery/CaseGallery.js';
import caseGalleryData from '../../api/caseImgs';
import { Link } from 'react-router-dom'

const DoctorProfilePage = () => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-2'} />
            <PageTitle pageTitle='Meet the Dentist' pagesub='' className={'wpo-page-title'}/>
            <div className="team-pg-area section-padding">
                <div className="container">
                    <div className="team-info-wrap">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="team-info-img">
                                    <img src={profileImage} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="team-info-text">
                                    <h2>Dr. Tri Tran, D.M.D</h2>
                                    <div>
                                        <p>
                                        Dr. Tran completed his undergraduate studies as a double major Magna Cum Laude in Biological Science and Chemistry at University of California, Irvine (UCI). Deeply interested in material science and healthcare, he went on to complete his Doctor of Dental Medicine training program at University of Nevada, Las Vegas (UNLV). Thanks to the early exposure to many aspects of dentistry in UNLV, Dr. Tran tries hard to keep updated with modern dentistry via continuing education. Some highlighted examples are 300 hours CE course in implant dentistry of Maxicourse AAID, 200 hours CE course in comprehensive dentistry of Spear Education, and 40 hours of CE course in implant dentistry of Hiossen Education.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="exprience-area">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* <div className="exprience-wrap">
                                    <h2>Personal Experience</h2>
                                    <p>The purpose of lorem ipsum is to create a natural looking block oftext (sentence, paragraph, page, etc.) that doesn't distract from thelayout. A practice not without controversy, laying out pages withmeaningless filler text can be very useful when the focus is meantto be on design, not content.There are many variations of passages of Lorem Ipsum available.</p>
                                    <p>But the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything embarrassing hidden in the middle of text generators on the Internet tend to repeat.</p>
                                </div> */}
                                {/* <div className="at-progress">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 custom-grid">
                                            <div className="progress yellow">
                                                <span className="progress-left">
                                                    <span className="progress-bar"></span>
                                                </span>
                                                <span className="progress-right">
                                                    <span className="progress-bar"></span>
                                                </span>
                                                <div className="progress-value">25+</div>
                                                <div className="progress-name"><span>Award</span></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 custom-grid">
                                            <div className="progress blue">
                                                <span className="progress-left">
                                                    <span className="progress-bar"></span>
                                                </span>
                                                <span className="progress-right">
                                                    <span className="progress-bar"></span>
                                                </span>
                                                <div className="progress-value">90%</div>
                                                <div className="progress-name"><span>Happy Client</span></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 custom-grid">
                                            <div className="progress pink">
                                                <span className="progress-left">
                                                    <span className="progress-bar"></span>
                                                </span>
                                                <span className="progress-right">
                                                    <span className="progress-bar"></span>
                                                </span>
                                                <div className="progress-value">95%</div>
                                                <div className="progress-name"><span>Project Done</span></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 custom-grid">
                                            <div className="progress green">
                                                <span className="progress-left">
                                                    <span className="progress-bar"></span>
                                                </span>
                                                <span className="progress-right">
                                                    <span className="progress-bar"></span>
                                                </span>
                                                <div className="progress-value">1000+</div>
                                                <div className="progress-name"><span>Project</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="education-area ex-wiget">
                                    <h2>Education</h2>
                                    <ul>
                                        <li>BS in Chemistry & Biological Science, UC Irvine</li>
                                        <li>DMD, UNLV (University of Nevada, Las Vegas) Dental School</li>
                                    </ul>
                                </div>
                                <div className="education-area ex-wiget">
                                    <h2>Language</h2>
                                    <ul>
                                        <li> English  </li>
                                        <li>Vietnamese</li>
                                    </ul>
                                </div>
                                {/* PUT PORFOLIO HERE */}
                                <div className="wpo-contact-area ex-wiget certificate">
                                    <h2>Treatment By Dr. Tran, DMD</h2>
                                    <OneCaseImagesSwiper  images={caseGalleryData[4].images} />
                                    <div className='btn-container' style={{marginTop:'2rem'}}>
                                        <Link onClick={ClickHandler} to="/case-gallery">
                                            <button type="submit" className="theme-btn-accent"> More Case Pictures</button>
                                        </Link>    
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer footerClass={'wpo-site-footer-s2'}/> */}
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default DoctorProfilePage;
