import React from 'react';

import { AiFillSchedule } from "react-icons/ai";
import { RiUserStarFill } from "react-icons/ri";
import { FaHandHoldingHeart } from "react-icons/fa6";

const featureData = {
    header:"Why here? Why us? Why Up Dental of Manvel?",
    title:"We believe in dental care not dental treatments. We believe in co-diagnosing and co-planning with our patients. We believe our patients will move with us, one step UP at a time.",
    features:[
        {
            'icon': AiFillSchedule,
            'title': 'Convenient Hours',
            'description': 'Late night and Saturday hours are available. Short Notice/Same Day/Emergency appointments are accommodated'
        },
        {
            'icon': RiUserStarFill ,
            'title': 'Minimal Referral',
            'description': "We are proud to have the privilege to practice a wide scope of comprehensive dentistry, which ranges from crowns and fillings, molar root canals, surgical extractions, to implants placement and restorations. We are pleased to be able to see patients of all ages from toddlers to elderly patients. However, we acknowledge the importance of referral when it is needed and have a supportive referral network to rely on.",
        },
        {
            'icon':  FaHandHoldingHeart ,
            'title': 'Wide Range of Payment Options',
            'description': "We try to focus on access to care for our patients so we decide to participate in-network with many insurance companies along with Medicaid and Medicare. Please check our list and/or call our office to check on insurance participation to be sure. Third Party financing company is also available for our patients' convenience as well."
        }
    ]
}


const OneFeature = ({icon, title, description}) => {
    const ReactIcon = icon;
    return (
        <div className="col-lg-4 col-md-6 col-12">
            <div className="feature-wrapper">
                <div className='icon-wrapper'>
                    <div className="icon-container">
                        <ReactIcon />
                    </div>
                </div>
                <div className="feature-description">
                    <h3>{title}</h3>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    );
};

const FeatureStyle2 = () => {
    return (
        // <section className='section-bg section-common features-section pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
        <section className='wpo-about-section features-section section-padding' >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="wpo-section-title">
                            {/* <span>Why US</span> */}
                            <h2>{featureData.header}</h2>
                            <p>{featureData.title}</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        featureData.features.map((singleFeature, idx) => 
                            <OneFeature
                                key={idx}
                                icon={singleFeature.icon} 
                                title={singleFeature.title}
                                description={singleFeature.description}
                            />
                        )
                    }
                </div>
            </div>
        </section>
    );
};

export default FeatureStyle2;