import xrayIcon from '../images/dental-services/x-ray.png'
// import examIcon from '../images/dental-services/exam.svg'
import cleaningIcon from '../images/dental-services/whitening.svg'
import fillingIcon from '../images/dental-services/fillings.svg'
import implantIcon from '../images/dental-services/implant.svg'
import extractionIcon from '../images/dental-services/extraction.svg'
// import crownIcon from '../images/dental-services/dental_crown.svg'
import crownIcon from '../images/dental-services/crown.png'
import dentureIcon from '../images/dental-services/denture.svg'
import canalIcon from '../images/dental-services/root-canal.png'
import orthoIcon from '../images/dental-services/brace.svg'
// import kidIcon from '../images/dental-services/kid.svg'
import kidIcon from '../images/dental-services/baby-teeth.png'
const dentalServices = [
    {
        serviceName:'Exams & X-Rays',
        icon:xrayIcon, //examIcon,
        description:"Exam is done to diagnose any issues with teeth, gum, and bone. Xrays also helps our doctor propose preventative care plan and monitor changes in our patients as we see them again. Exam and Xrays help us show our patient the recommended treatments to bring them back to the baseline of good oral health"
    },
    {
        serviceName:'Cleaning & Whitening',
        icon:cleaningIcon,
        description:"Regular dental cleaning is a more thorough cleaning that  will clean below the gum and in between the teeth to remove plaque and calculus patients usually have difficulty removing. Deep dental cleaning is when patients have bone loss and plaque and calculus on the root surface need to be removed. Whitening can be done when patients have no cavities and prefer to have whiter teeth"
    },
    {
        serviceName:'Fillings',
        icon:fillingIcon,
        description:"When food stay on teeth too long, it softens teeth and allows bacteria to cause a hole in teeth. The hole or cavity is cleaned and dental adhesive is used to bond white composite filling dental material to make the tooth whole again"
    },
    {
        serviceName:'Cosmetics',
        icon:crownIcon,
        description:"Almost everything in dentistry can be considered cosmetic and we acknowledge this is highly variable and personal. We offer cosmetic consultation to be on the same page with our patients on what they are looking for and what we can provide. However, some example of cosmetic dentistry can include smile make over (6 upper front veneers) or full mouth rehabilitation (crowns on all teeth) to restore worn out teeth."
    },
    {
        serviceName:'Orthodontics',
        icon:orthoIcon,
        description:"Straightening teeth and restoring the confidence in our patient have always been the goal of our doctor. Comprehensive orthodontic treatment will help us achieve this purpose. With the help of cephalometric X-Rays in house that show jaw relationship, we limit the referral to send our patients to nearby imaging centers."
    },
    {
        serviceName:'Root Canals & Endodontics',
        icon:canalIcon,
        description:"Saving teeth through root canal/endodontic is the most predictable and efficient method available up to date. Our doctor is very skilled in performing root canal that extend the lifetime of teeth that may need to be taken out. Painful teeth + root canals = healthy teeth that last"
    },
    {
        serviceName:'Extractions',
        icon:extractionIcon,
        description:"For teeth that cannot be saved anymore with root canals, they need to be extracted. Our doctor is highly skilled in surgical extractions. Removal of impacted wisdom teeth is practiced in the clinic as well with the help of laughing nitrous gas. Prescription can be given to manage the discomfort"
    },
    {
        serviceName:'Implants',
        icon:implantIcon,
        description:"We are proud to be able to offer in house CBCT (3D Xray that shows the quality and quantity of bone) and this allows us to plan and place implants on site. Implants are used to replace missing teeth and restore chewing ability to our patients."
    },
    {
        serviceName:'Full Dentures & Partial Dentures',
        icon:dentureIcon,
        description:"Full denture or partial dentures are perfectly reasonable choices to replace missing teeth in patients that are not good candidates for implants. The biggest complaint of wearing a removable prosthesis is discomfort but this can be managed with time and practice."
    },
    {
        serviceName:'Stainless Steel Crown',
        icon:kidIcon,
        description:"For our very young patients who unfortunately have big cavities, putting on silver caps can protect the baby teeth and maintain the space for their future adult teeth to come in. Losing baby teeth to cavities before permanent teeth come in can cause other teeth to shift in the empty space and cause asymmetrical crowding. Dental treatments are usually hard on patients of young age. Laughing nitrous gas has helped us very well in keeping our patients relaxed and comfortable without the need to use medication to put them to sleep."
    },
]
export default dentalServices;