import React from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';
import { AiFillStar } from "react-icons/ai";
// import Slider from "react-slick";
// import testimonials from '../../api/reviews';
// import { Link } from 'react-router-dom'
// import sliderSettings from '../../api/sliderSetting' 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const TestimoniCard = ({testimonail}) => {
    const {img, name, description, rating} = testimonail;
    const stars = [];
    for (let i = 1; i <= rating; i++) {
        stars.push(<li key={i-1} className='good-review' ><AiFillStar/></li>)
    }
    for( let i=1; i<=5-rating; i++){
        stars.push(<li key={i+3} className='bad-review' ><AiFillStar/></li>)
    }
    return (
        <div className='col-lg-3 col-md-4 col-sm-6 col-12 single-testimoni'>
            <div className="testimonial-card">
                <div className="testimonial-img">
                    <img src={img} alt="testimonial" />
                </div>
                <div className="testimonial-text">
                    <h3>{name}</h3>
                    <p>{description}</p>
                </div>
                <ul className='testimonial-rating'>
                    {stars}
                </ul>
            </div>
        </div>
    );
};

export const TestimoniCardToggle = ({testimonail}) => {

    return (
       <></>
    );
};

const TestimonialStyle3 = () => {
    // const ClickHandler = () => {
    //     window.scrollTo(10, 0);
    // }

    return (
        // <section className='testimonail-section section-bg section-common pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
        <section className='testimonail-section  section-padding' >
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <SectionTitle 
                        subTitle=""
                        title="What our patients have thought about us?"
                        />
                    </div>
                    <div className="col-lg-6">
                        <p className="pt-5">
                            Since we are a brand new start up dental office, we do not have any reviews yet. We understand reviews reflect many factors, such as how patients feel about the office, the staff, the doctor, and the quality of dental treatments. Hence, we feel it is a disservice to our patients to showcase our doctor's reviews from another setting. We are very thankful to our first patients who we hope will have the pleasure to give us reviews. 
                        </p>
                    </div>
                </div>
                
                {/* <div className='sliderWrapper'>
                    <Slider {...sliderSettings} className="testimoni-slider">
                        {
                            testimonials.slice(0,5).map((testimonail, testimonialIdx) => <TestimoniCard key={testimonialIdx} testimonail={testimonail} />)
                        }
                    </Slider>
                </div>

                <div className="btn-container" id="more-reviews-btn">
                    <Link onClick={ClickHandler} className="theme-btn-accent" to="/testimonial">View All Reviews</Link>
                </div> */}
               
            </div>
        </section>
    );
};

export default TestimonialStyle3;