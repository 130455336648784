import React from "react";
import { Link } from 'react-router-dom'
import hero1 from '../../images/case-gallery/case2/before.jpeg'

const SmileGalleryHero = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="wpo-about-section section-padding">
        {/* <section className="wpo-about-section section-padding"> */}
            <div className="container">
                <div className="row align-items-center">
                    {/* WORD ON THE LEFT */}
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-text">
                            {/* <span> About Us </span> */}
                            <h2>Case Gallery </h2>
                            <p > With tailored and patient centered approach, Dr. Tran is passionate about making people smile</p>
                            <div>
                                <Link onClick={ClickHandler} className="theme-btn-accent" to="/case-gallery">View more photos</Link>
                            </div>
                            
                        </div>
                    </div>
                    {/* PIC ON THE RIGHT */}
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-wrap">
                            <div className="wpo-about-img">
                                <img src={hero1} alt="" />
                            </div>
                         
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    )
}

export default SmileGalleryHero;