import React, {Fragment} from 'react';
// import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import FaqSection from '../../components/Faq/FaqSection'
// import { FooterWave } from '../../components/footer/Footer';

const FaQPage =() => {
    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-2'}/>
            {/* <PageTitle pageTitle={'Frequently Asked Question'} pagesub={''} className={'wpo-page-title'}/>  */}
            <FaqSection />
           
            
            {/* <FooterWave /> */}
            {/* <Footer footerClass={'wpo-site-footer-s2'}/> */}
            <Footer />
            <Scrollbar/>
        </Fragment>
    )
};

export default FaQPage;
